import React from 'react'
import githubIcon from '../assets/github.png'
import sourceIcon from '../assets/source.png'

const Card = ({title, shortDescription, imageSrc, githubLink, targetLink}) => {
    return (
        <div className='relative w-[442px] h-[232px] bg-black rounded-md overflow-hidden group'>
            <img className='block object-cover w-full h-full opacity-100 group-hover:opacity-50' src={imageSrc} alt="app source" />
            <div className='absolute top-0 w-full h-full flex justify-center items-center gap-x-8 transition-opacity ease-in-out opacity-0 group-hover:opacity-100'>
                {githubLink && 
                <a href={githubLink} target="_blank" rel="noopener noreferrer">
                    <img className='object-cover w-8 h-8 group-hover:cursor-pointer' src={githubIcon} alt="github icon" />
                </a>
                }
                {targetLink &&
                    <a href={targetLink} target="_blank" rel="noopener noreferrer">
                    <img className='object-cover w-8 h-8 group-hover:cursor-pointer' src={sourceIcon} alt="source icon" />
                </a>
                }
            </div>
            {/* <div className='absolute w-full h-full bg-black transition-opacity ease-in-out opacity-0 group-hover:opacity-50'></div> */}
            <div className='flex flex-col align-center bg-gradient-to-t from-black w-full px-3 py-3 transition-all ease-in-out -translate-y-1/2 group-hover:-translate-y-full'>
                <h1 className='text-neutral-50 text-2xl font-bold'>{title}</h1>
                <p className='text-neutral-50 text-sm transition-opacity ease-in-out opacity-0 group-hover:opacity-100'>{shortDescription}</p>
            </div>
        </div>
    )
  }
  
  

export default Card