import React from 'react'

const Footer = () => {
  return (
    <footer className='p-10 bg-black text-white text-left'>
        © {new Date().getFullYear()} SleepyBros. All rights reserved.
    </footer>
  )
}

export default Footer